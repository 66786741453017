import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid p-0 m-0" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "flex justify-content-between pb-2" }
const _hoisted_4 = ["onDragstart"]
const _hoisted_5 = {
  class: "font-semibold status-column",
  style: {
                          color: 'red',
                          border: `1px solid red`,
                        }
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onDragstart"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_CollapsibleSection = _resolveComponent("CollapsibleSection")!
  const _component_GanttChart = _resolveComponent("GanttChart")!
  const _component_Card = _resolveComponent("Card")!
  const _component_ScheduleBarOverlayForm = _resolveComponent("ScheduleBarOverlayForm")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_BarOverlayForm = _resolveComponent("BarOverlayForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Card, { class: "col-12" }, {
      content: _withCtx(() => [
        (_ctx.showScheduleGroupDropdown)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _cache[6] || (_cache[6] = _createElementVNode("span", { class: "block text-xl font-semibold mb-3" }, "Scheduling", -1)),
                _createVNode(_component_Dropdown, {
                  options: _ctx.getSchedulingGroupItems,
                  "model-value": _ctx.getSelectedScheduleGroup,
                  optionLabel: "scheduling_group_description",
                  optionValue: "scheduling_group",
                  onChange: _ctx.onScheduleGroupChange,
                  placeholder: "Select a Schedule Group"
                }, null, 8, ["options", "model-value", "onChange"])
              ]),
              _createElementVNode("div", {
                class: "flex",
                style: _normalizeStyle({
              flexDirection:
                _ctx.getSchedulingTabLayout === 'Orders'
                  ? 'column'
                  : 'column-reverse',
            })
              }, [
                _createVNode(_component_CollapsibleSection, {
                  isOpen: _ctx.sectionStatus.unscheduled,
                  header: "Unscheduled Orders",
                  "onUpdate:isOpen": _cache[4] || (_cache[4] = ($event: any) => (_ctx.toggleSectionIcon('unscheduled'))),
                  style: { marginBottom: '1rem' }
                }, {
                  content: _withCtx(() => [
                    _createVNode(_component_DataTable, {
                      ref: "productionSchedulingTable",
                      value: _ctx.getOrders,
                      class: "p-datatable-sm",
                      loading: _ctx.isLoadingOrders,
                      paginator: true,
                      rows: _ctx.rowsPerPage,
                      rowsPerPageOptions: [10, 25, 50, 100],
                      filterDisplay: "menu",
                      filters: _ctx.filters,
                      "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
                      breakpoint: "960px",
                      responsiveLayout: "stack",
                      sortOrder: _ctx.getSortOrderValue,
                      sortField: _ctx.getSortByValue,
                      rowClass: _ctx.rowClass,
                      onSort: _cache[3] || (_cache[3] = ($event: any) => (_ctx.sortData($event))),
                      onFilter: _ctx.handleFilter,
                      onRowClick: _ctx.rowClicked,
                      onPage: _ctx.handleOnPage,
                      lazy: true,
                      "total-records": _ctx.totalRecords,
                      paginatorTemplate: "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink  RowsPerPageDropdown CurrentPageReport",
                      currentPageReportTemplate: "Total Records: {totalRecords}"
                    }, {
                      empty: _withCtx(() => _cache[7] || (_cache[7] = [
                        _createTextVNode("No Items to display")
                      ])),
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "wo_id",
                          header: "WO Number"
                        }, {
                          body: _withCtx(({ data }) => [
                            _createElementVNode("div", {
                              class: "flex align-items-center",
                              style: {"cursor":"grab"},
                              draggable: true,
                              onDragstart: ($event: any) => (_ctx.itemDragStart(data, null)),
                              onDragend: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.itemDragEnd && _ctx.itemDragEnd(...args)))
                            }, [
                              _createElementVNode("i", {
                                class: "pi pi-circle-fill mr-2",
                                style: _normalizeStyle({ color: _ctx.dotColor(data) })
                              }, null, 4),
                              _createElementVNode("span", null, _toDisplayString(data.wo_id), 1)
                            ], 40, _hoisted_4)
                          ]),
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_InputText, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "ID",
                              class: "p-column-filter"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "part_no",
                          header: "Part Number",
                          bodyClass: "text-left"
                        }, {
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_InputText, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "Part Number",
                              class: "p-column-filter"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "description",
                          header: "Description",
                          class: "font-semibold"
                        }, {
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_InputText, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "Description",
                              class: "p-column-filter"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "rel_date",
                          header: "Run Date",
                          class: "font-semibold",
                          bodyClass: "text-center"
                        }, {
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_Calendar, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "Run Date",
                              class: "p-column-filter",
                              "selection-mode": "range"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        (_ctx.showRequestDateColumn)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 0,
                              sortable: "",
                              field: "so_li_req_date",
                              header: "Request Date",
                              class: "font-semibold",
                              bodyClass: "text-center"
                            }, {
                              filter: _withCtx(({ filterModel }) => [
                                _createVNode(_component_Calendar, {
                                  modelValue: filterModel.value,
                                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                  placeholder: "Request Date",
                                  class: "p-column-filter",
                                  "selection-mode": "range"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "req_date",
                          header: "Due Date",
                          class: "font-semibold",
                          bodyClass: "text-center"
                        }, {
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_Calendar, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "Due Date",
                              class: "p-column-filter",
                              "selection-mode": "range"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, {
                          sortable: "",
                          field: "wo_qty",
                          header: "Quantity",
                          class: "font-semibold",
                          bodyClass: "text-right"
                        }, {
                          filter: _withCtx(({ filterModel }) => [
                            _createVNode(_component_InputText, {
                              modelValue: filterModel.value,
                              "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                              placeholder: "Quantity",
                              class: "p-column-filter"
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 1
                        }),
                        (_ctx.showBomCountColumn)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 1,
                              sortable: "",
                              field: "so_li_bom_count",
                              header: "BOM Count",
                              class: "font-semibold",
                              bodyClass: "text-right"
                            }, {
                              filter: _withCtx(({ filterModel }) => [
                                _createVNode(_component_InputText, {
                                  modelValue: filterModel.value,
                                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                  placeholder: "BOM Count",
                                  class: "p-column-filter"
                                }, null, 8, ["modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.showUrgentColumn)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 2,
                              sortable: "",
                              field: "so_li_urgent",
                              header: "Urgent",
                              class: "font-semibold",
                              bodyClass: "text-center"
                            }, {
                              body: _withCtx(({ data }) => [
                                _withDirectives(_createElementVNode("div", _hoisted_5, " Urgent ", 512), [
                                  [_vShow, data.so_li_urgent === 'Y']
                                ])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.showDisplayStatus)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 3,
                              sortable: "",
                              field: "display_status",
                              header: "Status",
                              bodyClass: "text-center"
                            }, {
                              body: _withCtx(({ data }) => [
                                _withDirectives(_createElementVNode("div", {
                                  class: "font-semibold status-column",
                                  style: _normalizeStyle({
                          color: _ctx.statusColor(data.status),
                          border: `1px solid ${_ctx.statusColor(data.status)}`,
                        })
                                }, _toDisplayString(data.display_status), 5), [
                                  [_vShow, data.display_status]
                                ])
                              ]),
                              filter: _withCtx(({ filterModel }) => [
                                _createVNode(_component_MultiSelect, {
                                  modelValue: filterModel.value,
                                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                  options: _ctx.getFormatWorkOrderDisplayStatusList,
                                  optionValue: "id",
                                  optionLabel: "label",
                                  placeholder: "Status",
                                  class: "p-column-filter"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_Column, {
                              key: 4,
                              sortable: "",
                              field: "status",
                              header: "Status",
                              bodyClass: "text-center"
                            }, {
                              body: _withCtx(({ data }) => [
                                _withDirectives(_createElementVNode("div", {
                                  class: "font-semibold status-column",
                                  style: _normalizeStyle({
                          color: _ctx.statusColor(data.status),
                          border: `1px solid ${_ctx.statusColor(data.status)}`,
                        })
                                }, _toDisplayString(_ctx.getWoStatusValidValues[data.status]), 5), [
                                  [_vShow, data.status]
                                ])
                              ]),
                              filter: _withCtx(({ filterModel }) => [
                                _createVNode(_component_MultiSelect, {
                                  modelValue: filterModel.value,
                                  "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                                  options: _ctx.getFormatWorkOrderStatusList,
                                  optionValue: "id",
                                  optionLabel: "label",
                                  placeholder: "Status",
                                  class: "p-column-filter"
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                              ]),
                              _: 1
                            })),
                        (_ctx.hasMultipleOperations)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 5,
                              header: "Operations",
                              class: "font-semibold"
                            }, {
                              body: _withCtx(({ data }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.validOperations(data), (operation, index) => {
                                  return (_openBlock(), _createElementBlock("div", {
                                    key: index,
                                    class: "flex align-items-center",
                                    onClick: ($event: any) => (_ctx.operationClick($event, data, operation))
                                  }, [
                                    _createElementVNode("div", {
                                      class: "flex align-items-center mb-2",
                                      style: {"cursor":"grab"},
                                      draggable: true,
                                      onDragstart: ($event: any) => (_ctx.itemDragStart(data, operation)),
                                      onDragend: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.itemDragEnd && _ctx.itemDragEnd(...args)))
                                    }, [
                                      _createElementVNode("span", null, _toDisplayString(operation.oper) + " - " + _toDisplayString(operation.operation_desc), 1)
                                    ], 40, _hoisted_7)
                                  ], 8, _hoisted_6))
                                }), 128))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.showSubassembly)
                          ? (_openBlock(), _createBlock(_component_Column, {
                              key: 6,
                              field: "subassembly",
                              header: "Subassembly",
                              class: "font-semibold"
                            }, {
                              body: _withCtx(({ data }) => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(data.subassembly, (id) => {
                                  return (_openBlock(), _createElementBlock("span", { key: id }, [
                                    _createTextVNode(_toDisplayString(id) + " ", 1),
                                    _cache[8] || (_cache[8] = _createElementVNode("br", null, null, -1))
                                  ]))
                                }), 128))
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["value", "loading", "rows", "filters", "sortOrder", "sortField", "rowClass", "onFilter", "onRowClick", "onPage", "total-records"])
                  ]),
                  _: 1
                }, 8, ["isOpen"]),
                _createVNode(_component_CollapsibleSection, {
                  isOpen: _ctx.sectionStatus.scheduled,
                  header: "Scheduled Orders",
                  "onUpdate:isOpen": _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleSectionIcon('scheduled')))
                }, {
                  content: _withCtx(() => [
                    (_ctx.filteredWorkCenterList.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createVNode(_component_GanttChart, {
                            ref: "ganntChart",
                            workCenterList: _ctx.filteredWorkCenterList,
                            onSaveWorkOrders: _ctx.handleFetchWorkOrder
                          }, null, 8, ["workCenterList", "onSaveWorkOrders"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["isOpen"])
              ], 4)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, _cache[9] || (_cache[9] = [
              _createElementVNode("div", { class: "flex justify-content-between pb-2" }, [
                _createElementVNode("span", { class: "block text -xl font-semibold mb-3" }, "Scheduling")
              ], -1),
              _createElementVNode("div", { class: "flex justify-content-center" }, [
                _createElementVNode("span", { class: "text-lg" }, "No Schedule Groups available.")
              ], -1)
            ])))
      ]),
      _: 1
    }),
    _createVNode(_component_OverlayPanel, { ref: "addfromtablepopover" }, {
      default: _withCtx(() => [
        _createVNode(_component_ScheduleBarOverlayForm, {
          workOrder: _ctx.orderToSchedule,
          workCenterList: _ctx.filteredWorkCenterList,
          onScheduleOrder: _ctx.onScheduleOrder,
          onSaveNotes: _ctx.handleSaveNotes
        }, null, 8, ["workOrder", "workCenterList", "onScheduleOrder", "onSaveNotes"])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_OverlayPanel, { ref: "addoperationfromtablepopover" }, {
      default: _withCtx(() => [
        _createVNode(_component_BarOverlayForm, {
          barSelected: _ctx.operationSelected,
          isGenericBar: false,
          workOrder: _ctx.orderToSchedule,
          workCenterList: _ctx.filteredWorkCenterList,
          "show-delete-button": false,
          "show-add-operation-button": false,
          onSaveBarData: _ctx.addOperationToSchedule
        }, null, 8, ["barSelected", "workOrder", "workCenterList", "onSaveBarData"])
      ]),
      _: 1
    }, 512)
  ]))
}